import { Component, createContext, useContext, useEffect, useState } from 'react';
import useBus from 'use-bus';

import { navigate } from '@reach/router';

import config from '../../config';
import { usePlatformQueryClient } from '../../hooks';
import { isSSR } from '../../utils';
import authorize from './helpers/authorize';
import { fetchToken } from './helpers/fetchToken';
import { getCodeFromLocation } from './helpers/getCodeFromLocation';
import { getVerifierState } from './helpers/getVerifierState';
import { removeCodeFromLocation } from './helpers/removeCodeFromLocation';
import { removeStateFromStorage } from './helpers/removeStateFromStorage';

export default ({
  clientId,
  clientSecret,
  authorizeEndpoint,
  tokenEndpoint,
  scopes = [],
  storage = typeof window !== 'undefined' ? sessionStorage : undefined,
  fetch = typeof window !== 'undefined' ? window.fetch : undefined,
  busyIndicator = <></>,
}) => {
  const context = createContext({});
  const { Provider } = context;

  class Authenticated extends Component {
    static contextType = context;
    componentDidMount() {
      const { ensureAuthenticated } = this.context;
      ensureAuthenticated();
    }
    componentDidUpdate() {
      this.componentDidMount();
    }
    render() {
      const { token } = this.context;
      const { children } = this.props;

      if (config.useTokenAuth && !token) {
        return busyIndicator;
      } else {
        return children;
      }
    }
  }

  const useToken = () => {
    const { token } = useContext(context);

    if (!config.useTokenAuth) {
      return { access_token: null };
    }

    if (!token) {
      console.warn(`Trying to useToken() while not being authenticated.\nMake sure to useToken() only inside of an <Authenticated /> component.`);
    }
    return token;
  };

  return {
    AuthContext: ({ children }) => {
      const [token, setToken] = useState(null);
      const { invalidateQueries } = usePlatformQueryClient();

      const storedToken = storage?.getItem('pkce_token');

      if (storedToken && storedToken !== 'null' && JSON.stringify(token) !== storedToken) {
        setToken(JSON.parse(storedToken));
      }

      useEffect(() => {
        storage?.setItem('pkce_token', token ? JSON.stringify(token) : null);
      }, [token]);

      useBus('pkce.clear', async (event) => {
        storage?.setItem('pkce_token', null);
        await invalidateQueries(undefined, { refetchActive: false });

        if (!isSSR) {
          localStorage.removeItem('REACT_QUERY_OFFLINE_CACHE');
        }

        if (event.logoutUrl) {
          navigate(event.logoutUrl);
        }
      });

      // if we have no token, but code and verifier are present,
      // then we try to swap code for token
      useEffect(() => {
        if (config.useTokenAuth && !token) {
          const code = getCodeFromLocation({ location: window.location });
          const state = getVerifierState({ clientId, storage });
          if (code && state) {
            fetchToken({
              clientId,
              clientSecret,
              tokenEndpoint,
              code,
              state,
              fetch,
            })
              .then(setToken)
              .then(() => {
                removeCodeFromLocation();
                removeStateFromStorage({ clientId, storage });
                const params = new URLSearchParams(window.location.search);
                if (params.has('returnUrl') && !params.get('returnUrl').includes('callback')) {
                  navigate(params.get('returnUrl'));
                } else {
                  navigate('/app/assistant/board');
                }
              })
              .catch((e) => {
                console.error(e);
                alert(`Error fetching auth token: ${e.message}`);
              });
          }
        }
      }, [token]);

      const ensureAuthenticated = () => {
        if (!config.useTokenAuth) return;
        const code = getCodeFromLocation({ location: window.location });
        if (!token && !code) {
          authorize({ authorizeEndpoint, clientId, scopes });
        }
      };

      return <Provider value={{ token, ensureAuthenticated }}>{children}</Provider>;
    },
    Authenticated,
    useToken,
  };
};
