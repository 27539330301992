import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { dispatch } from 'use-bus';

import config, { apiUrl, defaultFetchOpts } from '../config';
import { useToken } from './auth';
import { useSessionStore } from './store';

const createQueryKey = (url, userId) => `${url}:${userId}`;

const queryFn = async (queryUrl, defaultFetchOpts, token, filter = false) => {
  const response = await fetch(queryUrl, defaultFetchOpts);

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const json = await response.json();

  if (filter && json?.ErrorCode == 401) {
    let logoutUrl = `${config.hostUrl}/account/logout?returnUrl=${encodeURIComponent(window.location.href)}`;

    if (config.useTokenAuth && token?.access_token) {
      logoutUrl += `&revoke_token=${token?.access_token}`;
    }

    dispatch({ type: 'pkce.clear', logoutUrl });
  }

  return new Promise((resolve) => {
    resolve(json);
  });
};

export const usePlatformQuery = (url) => {
  const session = useSessionStore((state) => state.session);
  const token = useToken();

  const queryKey = createQueryKey(url, session.UserId);
  const queryUrl = `${apiUrl}${url}`;

  if (config.useTokenAuth && token?.access_token) {
    defaultFetchOpts.headers.Authorization = `Bearer ${token.access_token}`;
  } else {
    defaultFetchOpts.credentials = 'include';
  }

  const result = useQuery(queryKey, async () => queryFn(queryUrl, defaultFetchOpts, token, true), {
    enabled: !!session,
    staleTime: 300000,
    refetchInterval: 300000,
    refetchOnWindowFocus: true,
  });

  return result;
};

export const usePlatformQueries = (urls) => {
  const session = useSessionStore((state) => state.session);
  const token = useToken();

  if (config.useTokenAuth && token?.access_token) {
    defaultFetchOpts.headers.Authorization = `Bearer ${token.access_token}`;
  } else {
    defaultFetchOpts.credentials = 'include';
  }

  urls = urls.map((url) => {
    const queryKey = createQueryKey(url, session.UserId);
    const queryUrl = `${apiUrl}${url}`;

    return {
      queryKey,
      queryFn: async () => queryFn(queryUrl, defaultFetchOpts, token, true),
      enabled: !!session,
      staleTime: 300000,
      refetchInterval: 300000,
      refetchOnWindowFocus: true,
    };
  });

  return useQueries(urls);
};

export const usePlatformQueryUnfiltered = (url) => {
  const session = useSessionStore((state) => state.session);
  const token = useToken();

  const queryKey = createQueryKey(url, session.UserId);
  const queryUrl = `${apiUrl}${url}`;

  if (config.useTokenAuth && token?.access_token) {
    defaultFetchOpts.headers.Authorization = `Bearer ${token.access_token}`;
  } else {
    defaultFetchOpts.credentials = 'include';
  }

  const result = useQuery(queryKey, async () => queryFn(queryUrl, defaultFetchOpts, token, false), {
    enabled: !!session,
    staleTime: 300000,
    refetchInterval: 300000,
    refetchOnWindowFocus: true,
  });

  return result;
};

export const usePlatformQueriesUnfiltered = (urls) => {
  const session = useSessionStore((state) => state.session);
  const token = useToken();

  if (config.useTokenAuth && token?.access_token) {
    defaultFetchOpts.headers.Authorization = `Bearer ${token.access_token}`;
  } else {
    defaultFetchOpts.credentials = 'include';
  }

  urls = urls.map((url) => {
    const queryKey = createQueryKey(url, session.UserId);
    const queryUrl = `${apiUrl}${url}`;

    return {
      queryKey,
      queryFn: async () => queryFn(queryUrl, defaultFetchOpts, token, false),
      enabled: !!session,
      staleTime: 300000,
      refetchInterval: 300000,
      refetchOnWindowFocus: true,
    };
  });

  return useQueries(urls);
};

export const usePlatformMutation = (url, opts) => {
  const session = useSessionStore((state) => state.session);
  const token = useToken();

  const queryKey = createQueryKey(url, session.UserId);
  const queryUrl = `${apiUrl}${url}`;

  defaultFetchOpts.headers['Content-Type'] = 'application/json';

  if (config.useTokenAuth && token?.access_token) {
    defaultFetchOpts.headers.Authorization = `Bearer ${token.access_token}`;
  } else {
    defaultFetchOpts.credentials = 'include';
  }

  const mergedOpts = {
    ...defaultFetchOpts,
    ...opts,
  };

  const result = useMutation(queryKey, async (body) => {
    if (mergedOpts.method == 'POST') {
      mergedOpts.body = JSON.stringify(body);
    }

    const response = await fetch(queryUrl, mergedOpts);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  });

  return result;
};

export const usePlatformQueryClient = () => {
  const session = useSessionStore((state) => state.session);
  const queryClient = useQueryClient();

  return {
    ...queryClient,
    invalidateQueries: (url, opts) => {
      const queryKey = createQueryKey(url, session.UserId);
      return queryClient.invalidateQueries(queryKey, opts);
    },
    cancelQueries: (url, opts) => {
      const queryKey = createQueryKey(url, session.UserId);
      return queryClient.cancelQueries(queryKey, opts);
    },
  };
};
