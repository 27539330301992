export const isSSR = typeof window === 'undefined';

export const isMobilePlatform = isSSR
  ? false
  : ((userAgent = navigator.userAgent.toLowerCase()) =>
      window.location.search.includes('mode=mobile') ||
      userAgent.includes('android') ||
      userAgent.includes('iphone') ||
      userAgent.includes('ipad') ||
      userAgent.includes('ipod'))();
