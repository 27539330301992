import { useEffect, useMemo, useRef, useState } from 'react';

import theme from '../theme';
import { isSSR } from '../utils';

const COLOR_SCHEMES = ['no-preference', 'dark', 'light']; // css prefers-color-scheme options
const DEFAULT_COLOR_SCHEME = 'light';

function resolveTargetColorScheme(scheme) {
  if (!COLOR_SCHEMES.includes((scheme = String(scheme).toLowerCase())) || scheme === 'no-preference') scheme = DEFAULT_COLOR_SCHEME;

  return scheme;
}

function getCurrentColorScheme() {
  const queries = {};

  return (getCurrentColorScheme = () => {
    for (const scheme of COLOR_SCHEMES) {
      const query = queries.hasOwnProperty(scheme) ? queries[scheme] : (queries[scheme] = isSSR ? null : matchMedia(`(prefers-color-scheme: ${scheme})`));

      if (query?.matches) return { query, scheme };
    }
  })();
}

// sets app color scheme to light or dark. checks for config-level defined theme setting,
// defaults to browser preference if not available or config setting is 'auto'.
// if 'auto', also subscribes to changes in browser preferences
export const useColorScheme = (targetColorScheme) => {
  const isAppMounted = useRef();
  const colorScheme = useRef();

  const targetScheme = useMemo(() => resolveTargetColorScheme(targetColorScheme), [targetColorScheme]);

  const [scheme, setColorScheme] = useState(() => {
    const current = getCurrentColorScheme();

    if (!current) return null;

    const { scheme } = (colorScheme.current = current);
    return scheme;
  });

  useEffect(() => {
    const { query } = colorScheme.current;

    query.addEventListener('change', schemeChangeHandler);
    isAppMounted.current = true;

    function schemeChangeHandler(evt) {
      if (!evt.matches) {
        this.removeEventListener('change', schemeChangeHandler);
        const { query, scheme } = (colorScheme.current = getCurrentColorScheme());

        isAppMounted.current && setColorScheme(scheme);
        query.addEventListener('change', schemeChangeHandler);
      }
    }

    return () => {
      const { query } = colorScheme.current;
      query.removeEventListener('change', schemeChangeHandler);
      isAppMounted.current = false;
    };
  }, []);

  if (theme.defaultMode && theme.defaultMode != 'auto') {
    return theme.defaultMode === 'light';
  }

  return scheme === targetScheme;
};
